import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faAirConditioner,
	faAppleWhole,
	faArrowRightLong,
	faArrowsMaximize,
	faBath,
	faBed,
	faBicycle,
	faBottleWater,
	faBuildingColumns,
	faCalendarDays,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faChevronUp,
	faCocktail,
	faCoffee,
	faCouch,
	faCroissant,
	faEnvelope,
	faGlassWater,
	faGolfFlagHole,
	faHeat,
	faHotTubPerson,
	faLink,
	faMasksTheater,
	faMicrophone,
	faMusic,
	faPersonHiking,
	faPersonSkiing,
	faPhone,
	faPizzaSlice,
	faRefrigerator,
	faShower,
	faSpa,
	faSprayCanSparkles,
	faStore,
	faSun,
	faToilet,
	faTree,
	faTv,
	faUsers,
	faUsersLine,
	faUtensils,
	faVault,
	faWater,
	faWifi,
	faWind,
	faWineGlass,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck, faBars, faLocationDot, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faPlay, faSearch } from '@fortawesome/pro-solid-svg-icons';
import {
	faFlipboard,
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTiktok,
	faTwitter,
	faWhatsapp,
	faXTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faStar, faQuoteLeft, faStarHalf } from '@fortawesome/free-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faAirConditioner,
	faAppleWhole,
	faArrowRightLong,
	faArrowsMaximize,
	faBars,
	faBath,
	faBed,
	faBicycle,
	faBottleWater,
	faBuildingColumns,
	faCalendarDays,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faChevronUp,
	faCircleCheck,
	faCocktail,
	faCoffee,
	faCouch,
	faCroissant,
	faEnvelope,
	faFacebook,
	faFlipboard,
	faGlassWater,
	faGolfFlagHole,
	faHeat,
	faHotTubPerson,
	faInstagram,
	faLink,
	faLinkedin,
	faLocationDot,
	faMasksTheater,
	faMicrophone,
	faMusic,
	faPersonHiking,
	faPersonSkiing,
	faPhone,
	faPinterest,
	faPizzaSlice,
	faPlay,
	faQuoteLeft,
	faRefrigerator,
	faSearch,
	faShower,
	faSoundcloud,
	faSpa,
	faSprayCanSparkles,
	faSpotify,
	faStar,
	faStarHalf,
	faStore,
	faSun,
	faTiktok,
	faTimes,
	faTree,
	faToilet,
	faTv,
	faTwitter,
	faUsers,
	faUsersLine,
	faUtensils,
	faVault,
	faWater,
	faWhatsapp,
	faWifi,
	faWind,
	faWineGlass,
	faXTwitter,
	faYoutube,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
